import { Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { updateAllReimberesementAtOnceApi } from "../../../apis/reimbersement.api";
import SubmitButton from "../../../components/button/SubmitButton";
import { closeModal, openModal } from "../../../store/actions/modalAction";

const AllReimburesementApprovalButton = ({ text, status, date, selectedUser, callBack, ...props }) => {

    const { user } = useSelector(state => state)

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const onStatusChange = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateAllReimberesementAtOnceApi({ userId: selectedUser._id, status, date, isAccountant: true }),
                (response) => {
                    setLoading(false);
                    callBack?.()
                    dispatch(callSnackBar("Reimburesement status changed.", SNACK_BAR_VARIETNS.suceess))
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Reimburesement status cant be changed.", SNACK_BAR_VARIETNS.error))
                }
            )
        );
    };


    const onClick = () => {
        dispatch(openModal({
            title: "Alert!",
            component: <Typography>{"Are sure to want to " + text?.toLowerCase() + " ?"}</Typography>,
            onConfirm: onStatusChange,
            size: "sm",
            confirmText: "Okay",
            onCancle: () => dispatch(closeModal())
        }))
    }

    if (selectedUser._id && user.data._id !== selectedUser._id) {
        return <Tooltip title={text}>
            <SubmitButton {...props} title={text} loading={loading} onClick={onClick}>

            </SubmitButton>
        </Tooltip>
    }
    return <>
    </>
}
export default AllReimburesementApprovalButton