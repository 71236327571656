import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomInput from '../../components/inputs/CustomInput'
import SubmitButton from '../../components/button/SubmitButton'
import { useDispatch } from 'react-redux'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'
import { DatePicker } from '@mui/x-date-pickers'
import DownloadSalaaryStructureSlipCsv from './actionButtons/DownloadSalaaryStructureSlipCsv'

function SalaryListCsv({
  loading,
}) {
  const currentDate = new Date();

  const previousMonth = new Date(currentDate);
  previousMonth.setMonth(currentDate.getMonth() - 1);
  const [date, setDate] = useState(previousMonth);
  console.log(date);
  return (
    <>
      <Box mt={3}>

        <DatePicker
          inputFormat='MMM-YYYY'
          views={['month', 'year']}
          onChange={(newValue) => setDate(newValue)}
          value={date}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              error={false}
            />
          )}
        />
        <Box mt={8} sx={{ float: "right", marginTop: "5px" }}>
          <DownloadSalaaryStructureSlipCsv
            date={date}
          />

        </Box>

      </Box>
    </>
  )
}

export default SalaryListCsv