import {
  AccountBalance,
  AccountBox,
  AccountTree,
  AddCard,
  AdminPanelSettings,
  AttachMoney,
  BusinessSharp,
  CakeOutlined,
  CalendarMonth,
  CallOutlined,
  Cancel,
  CardMembership,
  CreditCard,
  CreditCardOutlined,
  CurrencyExchange,
  CurrencyRupee,
  Edit,
  Euro,
  FoodBank,
  House,
  LocalAtm,
  LocalHospital,
  LocalHospitalOutlined,
  MailOutline,
  PinDrop,
  PointOfSale,
  RedeemRounded,
  Subtitles,
  SupervisedUserCircle,
  TableChart,
  TimeToLeave,
  TwoWheeler,
  VerifiedUser,
  WifiCalling3Outlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import { UserSearchBarNormal } from "../../components/inputs/SearchBar";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { USER_ROLES } from "../../utils/constants";
import {
  findObjectKeyByValue,
  toTitleCase,
  unEscapeStr,
} from "../../utils/helper";
// import { UserSalaryBreakDownFields, UserSalaryDeductionFields } from "../user/UserCreateUI"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { center } from "../../assets/css/theme/common";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableBody from "@mui/material/TableBody";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import ImageComponent from "../../components/inputs/ImageComponent";
import endpoints from "../../apis/endpoints";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../store/actions/modalAction";
import AddEmployeeController from "../resignation/AddEmployeeController";
import ProfilePicture from "./profilepic/ProfilePicture";

// export const BasicProfileDetailsComponent = ({ data }) => {
//     return (
//         <>
//             <Box sx={{ ...center }}>
//                 <Box sx={{ marginTop: "48px" }}>
//                     <Avatar sizes="large" sx={{ width: "130px", height: "130px", bgcolor: "primary.main" }}>
//                         <Typography sx={{fontSize:"3.5rem"}}>
//                             {
//                                 data.name[0].toUpperCase()
//                             }
//                         </Typography>
//                     </Avatar>
//                     <Typography sx={{ marginTop: "24px" ,...center}} variant="h3" >{toTitleCase(data.name)}</Typography>
//                     <Typography variant="h6" sx={{ ...center, ml: "0" }} color={"text.200"} ml={2}>{data.designation}</Typography>
//                 </Box>

//             </Box>
//         </>
//     )
// }

export const SalaryBreakDownComponent = ({ data, onUpdateData }) => {
  const { user } = useSelector((state) => state);
  const [updateMode, setUpdateMode] = useState(false);

  const [loading, setloading] = useState(false);
  const [state, setState] = useState({ ...data.salary, err: "" });
  const countSalary = (salary) => {
    let finalSalary = 0;
    if (salary && Object.keys(salary).length > 0) {
      Object.keys(salary).forEach((breakdown) => {
        finalSalary += salary[breakdown];
      });
    }
    return finalSalary;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    onUpdateData(
      state,
      "salary",
      () => {
        setState({ ...state, err: "" });
        setUpdateMode(false);
        setloading(false);
      },
      (err) => {
        setState({ ...state, err });
        setloading(false);
      }
    );
  };
  return (
    <>
      <Box
        mt={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">Salary Breakdown</Typography>

        {(user.data.role === USER_ROLES.admin ||
          user.data.role === USER_ROLES.hr) &&
          user.data._id != data._id && (
            <IconButton
              onClick={() => {
                setUpdateMode(!updateMode);
              }}
              size="small"
            >
              {updateMode ? <Cancel /> : <Edit />}
            </IconButton>
          )}
      </Box>
      {updateMode && (
        <Typography variant="h6" color={"error"}>
          {state.err}
        </Typography>
      )}

      <Box
        mt={3}
        p={3}
        bgcolor="text.600"
        component={"form"}
        onSubmit={onSubmit}
      >
        {!updateMode && (
          <>
            <Grid container rowSpacing={3} columnSpacing={4}>
              <Grid item xs={6}>
                <Typography variant="h5">Basic Salary</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.basicSalary ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">House Rent Allowance</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.houseRentAllowance ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Conveyence Allowance</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.conveyenceAllowance ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Food Allowance</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.foodAllowance ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Other Allowance</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.otherAllowance ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Incentive</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.incencitive ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Medical Allowance</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.medicalAllowance ?? "-"}
                </Typography>
              </Grid>
            </Grid>

            {
              <Grid
                container
                rowSpacing={3}
                columnSpacing={3}
                mt={2}
                pt={2}
                sx={{ borderTop: "1px solid black" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h5">Total CTC</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="grey.main">
                    ₹ {countSalary(state)}
                  </Typography>
                </Grid>
              </Grid>
            }
          </>
        )}

        {/* {updateMode && <>

                <UserSalaryBreakDownFields fields={state} setFields={setState} loading={loading} />


            </>} */}

        {updateMode && (
          <CenteredBox mt={3}>
            <SubmitButton
              loading={loading}
              title={"Update Changes"}
              variant="outlined"
              type="submit"
            ></SubmitButton>
          </CenteredBox>
        )}
      </Box>
    </>
  );
};

export const SalaryDeductionComponent = ({ data, onUpdateData }) => {
  const { user } = useSelector((state) => state);
  const [updateMode, setUpdateMode] = useState(false);

  const [loading, setloading] = useState(false);
  const [state, setState] = useState({ ...data.taxes, err: "" });

  const onSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    onUpdateData(
      state,
      "taxes",
      () => {
        setState({ ...state, err: "" });
        setUpdateMode(false);
        setloading(false);
      },
      (err) => {
        setState({ ...state, err });
        setloading(false);
      }
    );
  };

  return (
    <>
      <Box
        mt={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3">Salary Deductions</Typography>

        {(user.data.role === USER_ROLES.admin ||
          user.data.role === USER_ROLES.hr) &&
          user.data._id != data._id && (
            <IconButton
              onClick={() => {
                setUpdateMode(!updateMode);
              }}
              size="small"
            >
              {updateMode ? <Cancel /> : <Edit />}
            </IconButton>
          )}
      </Box>
      {updateMode && (
        <Typography variant="h6" color={"error"}>
          {state.err}
        </Typography>
      )}

      <Box
        mt={3}
        p={3}
        bgcolor="text.600"
        component={"form"}
        onSubmit={onSubmit}
      >
        {!updateMode && (
          <>
            <Grid container rowSpacing={3} columnSpacing={4}>
              <Grid item xs={6}>
                <Typography variant="h5">Provident Fund</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.providentFund ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Professional Tax</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.proffesionalTax ?? "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">ESIC</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="grey.main">
                  ₹ {state.esic ?? "-"}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {/* {updateMode && <>

                <UserSalaryDeductionFields fields={state} setFields={setState} loading={loading} />


            </>} */}

        {updateMode && (
          <CenteredBox mt={3}>
            <SubmitButton
              loading={loading}
              title={"Update Changes"}
              variant="outlined"
              type="submit"
            ></SubmitButton>
          </CenteredBox>
        )}
      </Box>
    </>
  );
};

const ProfileUi = ({
  state,
  callBack,
  isFromViewMore,
  fieldLoading,
  onUpdateField,
  onUpdateData,
  loading,
  isFromModal,
  getFile,
}) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [personal, setPersonal] = useState(true);
  const [company, setCompany] = useState(false);
  const [bank, setBank] = useState(false);
  const [salary, setSalary] = useState(false);
  const [taxes, setTaxes] = useState(false);
  const [document, setDocument] = useState(false);

  const handleSalaryStructure = () => {
    navigate("/salary/salary-structure");
    dispatch(closeModal());
  };

  const collapsContainer = {
    borderBottom: "1px solid #7F7F7F",
  };
  const tableCell = {
    border: "none",
  };
  return (
    <>
      {!loading && state && (
        <Box mt={3} mb={3}>
          <Box sx={{ ...center }}>
            <Box
              sx={{
                marginTop: "48px",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <ImageComponent
                src={state.profile_url}
                isAvatar
                avtarTitle={
                  state.first_name &&
                  state.first_name[0] &&
                  state.first_name[0].toUpperCase()
                }
              /> */}
              <ProfilePicture></ProfilePicture>
              <Typography sx={{ marginTop: "24px", ...center }} variant="h3">
                {toTitleCase(state.first_name) +
                  " " +
                  toTitleCase(state.last_name)}
              </Typography>
              <Typography
                variant="h5"
                sx={{ ...center, ml: "0" }}
                color={"text.200"}
                ml={2}
              >
                ({state.employee_id})
              </Typography>
              <Typography
                variant="h6"
                sx={{ ...center, ml: "0" }}
                color={"text.200"}
                ml={2}
              >
                {state.designation}
              </Typography>
            </Box>
          </Box>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "48px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Personal details"
              onClick={() => setPersonal(!personal)}
              action={
                <IconButton aria-label="expand" size="small">
                  {personal ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={personal} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <VerifiedUser />
                                &nbsp;&nbsp;Employee Id
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.employee_id ? state.employee_id : "NA"}
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <BloodtypeIcon />
                                &nbsp;&nbsp;Blood Group
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.blood_group &&
                                toTitleCase(state.blood_group)}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <MailOutline />
                                &nbsp;&nbsp;Email
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Link href={`mailto:${state.email}`}>
                                {state.email}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CallOutlined />
                                &nbsp;&nbsp;Phone Number
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.phone_country_code} {state.phone}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CakeOutlined />
                                &nbsp;&nbsp;Date Of Birth
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.dob
                                ? moment(state.dob).format("DD-MM-YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <WifiCalling3Outlined />
                                &nbsp;&nbsp;Emergency Number
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.phone_emergency_country_code}{" "}
                              {state.phone_emergency}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan={1}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <LocalHospitalOutlined />
                                &nbsp;&nbsp;Medical Remarks
                              </Typography>
                            </TableCell>

                            <TableCell colSpan={3}>
                              {state.medical_remarks}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan={1}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <PinDrop />
                                &nbsp;&nbsp;Address
                              </Typography>
                            </TableCell>

                            <TableCell colSpan={3}>
                              {state.address_line1},{" "}
                              {state.address_line2
                                ? state.address_line2 + ", "
                                : ""}{" "}
                              {state.zip_code},{state.city}, {state.state},{" "}
                              {state.country}.
                            </TableCell>
                          </TableRow>

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CreditCard />
                                &nbsp;&nbsp;PAN No.
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.pan_no}
                              {state.pan_url && (
                                <FileDownloadComponent src={state.pan_url} />
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CardMembership />
                                &nbsp;&nbsp;Aadhar Number
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {state.aadhar_no}{" "}
                              {state.addhar_url && (
                                <FileDownloadComponent src={state.addhar_url} />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "12px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Company Details"
              onClick={() => setCompany(!company)}
              action={
                <IconButton aria-label="expand" size="small">
                  {company ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={company} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AccountTree />
                                &nbsp;&nbsp;Branch
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.branch && state.branch.name
                                ? state.branch.name
                                : "NA"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AccountBox />
                                &nbsp;&nbsp;Employee Type
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.employee_type && state.employee_type.name
                                ? toTitleCase(state.employee_type.name)
                                : "NA"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AdminPanelSettings />
                                &nbsp;&nbsp;Role
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ textTransform: "uppercase", ...tableCell }}
                            >
                              {state.role &&
                                findObjectKeyByValue(state.role, USER_ROLES)}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CalendarMonth />
                                &nbsp;&nbsp;Joining Date
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.join_date
                                ? moment(state.join_date).format("DD-MM-YYYY")
                                : "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <BusinessSharp />
                                &nbsp;&nbsp;Department
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.department && state.department.name
                                ? state.department.name
                                : "NA"}
                            </TableCell>

                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <SupervisedUserCircle />
                                &nbsp;&nbsp;Reporting Person
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.parent_id &&
                                Array.isArray(state.parent_id) &&
                                Array.isArray(state.parent_id) &&
                                state.parent_id[0]
                                ? state.parent_id[0].first_name +
                                " " +
                                state.parent_id[0].last_name
                                : "NA"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <BusinessSharp />
                                &nbsp;&nbsp;Secondary Departments
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.secondary_department &&
                                state.secondary_department.length > 0
                                ? state.secondary_department.map(
                                  (dept, index) => (
                                    <Typography key={index}>
                                      {dept.department && dept.department.name
                                        ? dept.department.name
                                        : "NA"}
                                    </Typography>
                                  )
                                )
                                : "NA"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <SupervisedUserCircle />
                                &nbsp;&nbsp;Secondary Reporting People
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.secondary_department &&
                                state.secondary_department.length > 0
                                ? state.secondary_department.map(
                                  (dept, index) => (
                                    <Typography key={index}>
                                      {dept.parent_id
                                        ? `${dept.parent_id.first_name} ${dept.parent_id.last_name}`
                                        : "NA"}
                                    </Typography>
                                  )
                                )
                                : "NA"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "12px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Bank details"
              onClick={() => setBank(!bank)}
              action={
                <IconButton aria-label="expand" size="small">
                  {bank ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={bank} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AccountBalance />
                                &nbsp;&nbsp;Bank Name
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.bank_details.bank_name}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <Subtitles />
                                &nbsp;&nbsp;Bank IFSC
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.bank_details.ifsc_code}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AccountBalance />
                                &nbsp;&nbsp;Bank Account No.
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.bank_details.account_no}
                            </TableCell>

                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AccountBalance />
                                &nbsp;&nbsp;Bank Proof.
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.bank_details.cancel_check && (
                                <FileDownloadComponent
                                  src={state.bank_details.cancel_check}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "12px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Salary Details"
              onClick={() => setSalary(!salary)}
              action={
                <IconButton aria-label="expand" size="small">
                  {salary ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={salary} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <LocalAtm />
                                &nbsp;&nbsp;CTC Amount
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.ctc_amount
                                ? state.salary.ctc_amount
                                : "0"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AttachMoney />
                                &nbsp;&nbsp;Basic Salary
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.basic_salary
                                ? state.salary.basic_salary
                                : "0"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <PointOfSale />
                                &nbsp;&nbsp;Employer PF
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.employee_pf
                                ? state.salary.employee_pf
                                : "0"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <House />
                                &nbsp;&nbsp;House Rent Allowance
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.house_rent_allowance
                                ? state.salary.house_rent_allowance
                                : "0"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <Euro />
                                &nbsp;&nbsp;Employer ESIC
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.employee_esic
                                ? state.salary.employee_esic
                                : "0"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <RedeemRounded />
                                &nbsp;&nbsp;Conveyence Allowance
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.conveyence_allowance
                                ? state.salary.conveyence_allowance
                                : "0"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CurrencyRupee />
                                &nbsp;&nbsp;Gross Amount
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.gross_amount
                                ? state.salary.gross_amount
                                : "0"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <AddCard />
                                &nbsp;&nbsp;Other Allowance
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.salary.other_allowance
                                ? state.salary.other_allowance
                                : "0"}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <TimeToLeave />
                                &nbsp;&nbsp;Four Wheeler Amount Per Km
                              </Typography>
                            </TableCell>

                            <TableCell sx={tableCell}>
                              {state.four_wheel_amount_per_km
                                ? state.four_wheel_amount_per_km
                                : "0"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <CurrencyExchange />
                                &nbsp;&nbsp;Total Earning
                              </Typography>
                            </TableCell>

                            <TableCell sx={tableCell}>
                              {Number(state.salary.basic_salary ?? 0) +
                                Number(state.salary.house_rent_allowance ?? 0) +
                                Number(state.salary.conveyence_allowance ?? 0) +
                                Number(state.salary.other_allowance ?? 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell sx={{border: "none"  }}>
                                                     <SubmitButton onClick={handleSalaryStructure} sx={{width:"30%",float:"right"}} title={'View Salary Structure'}></SubmitButton>
                                                    </TableCell> */}
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <TwoWheeler />
                                &nbsp;&nbsp;Two Wheeler Amount Per Km
                              </Typography>
                            </TableCell>

                            <TableCell sx={tableCell}>
                              {state.two_wheel_amount_per_km
                                ? state.two_wheel_amount_per_km
                                : "0"}
                            </TableCell>

                            {/* <TableCell sx={{border: "none"  }}>
                                                     <SubmitButton onClick={handleSalaryStructure} sx={{width:"30%",float:"right"}} title={'View Salary Structure'}></SubmitButton>
                                                    </TableCell> */}
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Box sx={{ float: "left", ml: "12px" }}>
                        <SubmitButton
                          onClick={handleSalaryStructure}
                          sx={{ width: "20%", mr: "63px" }}
                          title={"View Salary Structure"}
                        ></SubmitButton>
                      </Box>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "12px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Taxes Details"
              onClick={() => setTaxes(!taxes)}
              action={
                <IconButton aria-label="expand" size="small">
                  {taxes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={taxes} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;Professional Tax
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.taxes && state.taxes.proffesional_tax
                                ? state.taxes.proffesional_tax
                                : 0}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;PF ID
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.pf.pf_id ? state.pf.pf_id : "NA"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;PF
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.pf.pf_percent ? state.pf.pf_percent : 0}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;UAN ID
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.pf.uan_id ? state.pf.uan_id : "NA"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;ESIC ID
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.esic.esic_id ? state.esic.esic_id : "NA"}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;ESIC
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.esic.esic_percent
                                ? state.esic.esic_percent
                                : 0}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
          <Card
            sx={{
              minWidth: 300,
              border: "1px solid #7F7F7F",
              marginTop: "12px",
              boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardHeader
              title="Document"
              onClick={() => setDocument(!document)}
              action={
                <IconButton aria-label="expand" size="small">
                  {document ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              }
              sx={collapsContainer}
            ></CardHeader>
            <Box>
              <Collapse in={document} timeout="auto">
                <CardContent>
                  <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                    <TableContainer
                      sx={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <EventAvailableIcon />
                                &nbsp;&nbsp;NDA File
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.nda_url ? (
                                <FileDownloadComponent src={state.nda_url} />
                              ) : (
                                "NA"
                              )}
                            </TableCell>
                            <TableCell sx={tableCell}>
                              <Typography
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  lineHeight: "100%",
                                }}
                                variant="h6"
                              >
                                <PeopleAltOutlinedIcon />
                                &nbsp;&nbsp;Agreement File
                              </Typography>
                            </TableCell>
                            <TableCell sx={tableCell}>
                              {state.aggreement_url ? (
                                <FileDownloadComponent
                                  src={state.aggreement_url}
                                />
                              ) : (
                                "NA"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                </CardContent>
              </Collapse>
            </Box>
          </Card>
        </Box>
      )}
      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      {loading && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}
    </>
  );
};
export default ProfileUi;
