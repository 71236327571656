import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"
import { getDailyLogsApi, raiseCoffApi, raiseLeaveApi } from "../../../apis/leave.api"
import moment from "moment"
import { CALENDAR_ITEM_TYPES, HALF_TYPE, HALF_TYPE_OBJ, LOGS_NAMES, SNACK_BAR_VARIETNS } from "../../../utils/constants"
import CoffRaiseUi from "./CoffRaiseUi"
import { useNavigate } from "react-router-dom"
import { calculateWorkingHours } from "../../../utils/helper"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import RaiseEmployeeCoffUi from "./RaiseEmployeeCoffUi"

const RaiseEmployeeCoffController = ({ userId, weeklyOf, callBack = () => { } }) => {
    const [loading, setLoading] = useState(false)
    const validate = useValidate()
    const dispatch = useDispatch()
    const { leaveBalance, holiday, user } = useSelector(state => state)

    const disableDates = (date) => {
        // let isHoliday = false
        // if (holiday.data && Array.isArray(holiday.data)) {
        //     for (let i = 0; i < holiday.data.length; i++) {
        //         const item = holiday.data[i]
        //         const incomingDate = moment(item.date)
        //         isHoliday =
        //             incomingDate.get('dates') == date.get('dates') &&
        //             incomingDate.get('months') == date.get('months') &&
        //             incomingDate.get('years') == date.get('years')
        //         if (isHoliday) break
        //     }
        // }
        // let isWeeklyOf = false
        // if (!isHoliday) {

        //     if (weeklyOf && Array.isArray(weeklyOf) && weeklyOf.includes(date.day())) {
        //         isWeeklyOf = true
        //     } else if (user.data && user.data.weekly_of && Array.isArray(user.data.weekly_of) && user.data.weekly_of.includes(date.day())) {
        //         isWeeklyOf = true
        //     }
        // }
        // return !(isWeeklyOf || isHoliday)
    }

    const [fields, setFields] = useState({
        err: '',
        applicationDate: moment(),
        userId: userId,
        remarks: "",
        date: moment(),

        time_duration: null
    })
console.log("object userId", fields?.userId);

    const [list, setList] = useState({})
    const [filters, setFilters] = useState({})
    const fetchList = async () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getDailyLogsApi({ date:fields.date, userId:user.data.id, duration: 'daily' }),
                async (response) => {
                    setLoading(false)

                    const logsWithOutPeriodic = response && Array.isArray(response) ? response
                        .filter(item => [CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakOUTLog, CALENDAR_ITEM_TYPES.breakInLog].includes(item.logType))
                        .map((item) => ({
                            ...item,
                            logTime: item.createdAt,
                            type: item.logType,
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))

                        : []

                    const logsWithPeriodic = response && Array.isArray(response) ? response
                        .filter(item => ![CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakOUTLog, CALENDAR_ITEM_TYPES.breakInLog].includes(item.logType))
                        .map((item) => ({
                            logTime: item.createdAt,
                            type: item.logType,
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))

                        : []

                    let finalData = {
                        ...calculateWorkingHours(logsWithOutPeriodic),
                        logsWithPeriodic,
                        logsWithOutPeriodic,
                        allLogs: response && Array.isArray(response) && response.map((item) => ({
                            logTime: item.createdAt,
                            address: LOGS_NAMES[item.logType] + "-" + (moment(item.createdAt).format("HH:mm")),
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))
                    }
                    setList(finalData)

                },
                (err) => {
                    setLoading(false)


                    setList({})
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )

    }
    useEffect(() => {
        fetchList()
    }, [fields.date])

console.log(" data fetch for a date time",list);

 


    const validationSchema = useMemo(() => ([


        {
            required: true,
            value: fields.date,
            field: 'Comp-Off  Date ',
            custom: () => {
                return !disableDates(fields.date)
            }
        },

        {
            required: true,
            value: fields.remarks,
            field: 'Remarks',
        }
    ]), [fields])

    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {

            const leaveData = { ...fields }


            leaveData.date = fields.date.format("YYYY-MM-DD")

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await raiseCoffApi(leaveData),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        navigate("/calendar/")
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    return <RaiseEmployeeCoffUi loading={loading} list={list} fields={fields} onSubmit={onSubmit} disableDates={disableDates} setFields={setFields} />
}
export default memo(RaiseEmployeeCoffController)