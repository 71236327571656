import React from 'react'
import AsyncDropDown from '../../components/inputs/AsyncDropDown';
import { fetchDepartments } from '../../apis/department';
import { Box, CircularProgress, FormHelperText, Grid, Typography } from '@mui/material';
import CustomInput from '../../components/inputs/CustomInput';
import { UserSearchBarNormal } from '../../components/inputs/SearchBar';
import SubmitButton from '../../components/button/SubmitButton';
import { CenteredBox } from '../../components/layouts/common/boxes';

const MultipleDepartmentAddUi = ({ MultipleDepartmentAdd, Setfield, field, loading, title, data, updateMultipleDepartment, setData }) => {

    return (
        loading ? <CenteredBox><CircularProgress /></CenteredBox> :
            <>
                {!title && <Grid xs={12} md={6} item>
                    <Box>
                        <UserSearchBarNormal
                            defaultVal={
                                field.id
                                    ? {
                                        _id: field?.id,
                                        name: field?.parent_id_name,
                                    }
                                    : null
                            }
                            inputProps={{
                                label: "Select User",
                            }}
                            onUserChange={(newVal) => {
                                Setfield({
                                    ...field,
                                    err: "",
                                    id: newVal?._id,
                                });
                            }}
                        />
                        <FormHelperText>
                            {/* {getErrorMessage("Reporing Person")} */}
                        </FormHelperText>
                    </Box>
                </Grid>}



                {!loading && title ? data?.secondary_department?.length > 0 ? data?.secondary_department?.map((item, index) => (
                    <><Grid xs={12} md={6} item>
                        <Box>
                            <AsyncDropDown
                                defaultVal={
                                    { _id: item?.department?._id, name: item?.department?.name }
                                }
                                InputComponent={(props) => (
                                    <CustomInput
                                        label="Department"
                                        placeholder="Select Department"
                                        {...props}
                                    />
                                )}
                                label="Department*"
                                lazyFun={async (props) => {
                                    return await fetchDepartments({ ...props });
                                }}
                                OptionComponent={({ option, ...rest }) => {
                                    return (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            {...rest}
                                        >
                                            <Typography ml={3} variant="h5">
                                                {option?.name}
                                            </Typography>
                                        </Box>
                                    );
                                }}
                                onChange={async (changedVal) => {
                                    setData((prev) => {
                                        let temp = prev
                                        temp.secondary_department[index] = { ...temp.secondary_department[index], department: changedVal }
                                        return temp
                                    });
                                }}
                                titleKey={"name"}
                                valueKey={"_id"}
                            />
                        </Box>
                    </Grid>
                        <Grid xs={12} md={6} item>
                            <Box>
                                <UserSearchBarNormal
                                    defaultVal={
                                        { _id: item?.parent_id?._id, name: item?.parent_id?.first_name + " " + item?.parent_id?.last_name }
                                    }
                                    inputProps={{
                                        label: "Reporting Person",
                                    }}
                                    onUserChange={(newVal) => {
                                        setData((prev) => {
                                            let temp = prev
                                            temp.secondary_department[index] = { ...temp.secondary_department[index], parent_id: newVal }
                                            return temp
                                        });
                                    }}
                                />
                                <FormHelperText>
                                    {/* {getErrorMessage("Reporing Person")} */}
                                </FormHelperText>
                            </Box>
                        </Grid></>
                )) : <CenteredBox><Typography>No Secondary Department.</Typography></CenteredBox>
                    : <>
                        <Grid xs={12} md={6} item>
                            <Box>
                                <AsyncDropDown
                                    defaultVal={
                                        field.department
                                            ? {
                                                _id: field?.department,
                                                name: field?.department_name,
                                            }
                                            : null
                                    }
                                    InputComponent={(props) => (
                                        <CustomInput
                                            label="Department"
                                            placeholder="Select Department"
                                            {...props}
                                        />
                                    )}
                                    label="Department*"
                                    lazyFun={async (props) => {
                                        return await fetchDepartments({ ...props });
                                    }}
                                    OptionComponent={({ option, ...rest }) => {
                                        return (
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                {...rest}
                                            >
                                                <Typography ml={3} variant="h5">
                                                    {option?.name}
                                                </Typography>
                                            </Box>
                                        );
                                    }}
                                    onChange={async (changedVal) => {
                                        Setfield({
                                            ...field,
                                            err: "",
                                            department: changedVal?._id,
                                        });
                                    }}
                                    titleKey={"name"}
                                    valueKey={"_id"}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <Box>
                                <UserSearchBarNormal
                                    defaultVal={
                                        field?.parent_id
                                            ? {
                                                _id: field?.parent_id,
                                                name: field?.parent_id_name,
                                            }
                                            : null
                                    }
                                    inputProps={{
                                        label: "Reporting Person",
                                    }}
                                    onUserChange={(newVal) => {
                                        Setfield({
                                            ...field,
                                            err: "",
                                            parent_id: newVal?._id,
                                        });
                                    }}
                                />
                                <FormHelperText>
                                    {/* {getErrorMessage("Reporing Person")} */}
                                </FormHelperText>
                            </Box>
                        </Grid>
                    </>}



                {(!title || title && data?.secondary_department?.length > 0) && <Grid xs={12} md={6} item>
                    <SubmitButton
                        loading={loading}
                        variant="contained"
                        onClick={!title ? MultipleDepartmentAdd : updateMultipleDepartment}
                        title={title ?? "Multiple Department Add"}
                    />
                </Grid>}
            </>
    )
}

export default MultipleDepartmentAddUi